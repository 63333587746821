// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wKCJl7umVdrEp82h3UtV{margin-top:40px;min-height:300px;position:relative;padding:20px 0;display:flex;align-items:center;justify-content:center;flex-direction:column}.CIQeImwPC31syhh1V3OO,.g4qNlH1orMmchulxtMw0{margin:10px 0;font-size:28px;font-weight:760;text-align:center}.CIQeImwPC31syhh1V3OO{color:var(--tg-theme-button-color)}.g4qNlH1orMmchulxtMw0{color:#ff0026}.gCrK0F_U5_4wVnBMEhra{font-size:16px;font-weight:760;text-align:center;color:var(--tg-theme-text-color)}`, "",{"version":3,"sources":["webpack://./src/components/status/status.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CAGF,4CAEE,aAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,kCAAA,CAGF,sBACE,aAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gCAAA","sourcesContent":[".inner {\n  margin-top: 40px;\n  min-height: 300px;\n  position: relative;\n  padding: 20px 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.ready,\n.error {\n  margin: 10px 0;\n  font-size: 28px;\n  font-weight: 760;\n  text-align: center;\n}\n\n.ready {\n  color: var(--tg-theme-button-color);\n}\n\n.error {\n  color: #FF0026;\n}\n\n.text {\n  font-size: 16px;\n  font-weight: 760;\n  text-align: center;\n  color: var(--tg-theme-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `wKCJl7umVdrEp82h3UtV`,
	"ready": `CIQeImwPC31syhh1V3OO`,
	"error": `g4qNlH1orMmchulxtMw0`,
	"text": `gCrK0F_U5_4wVnBMEhra`
};
export default ___CSS_LOADER_EXPORT___;

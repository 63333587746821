import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Title } from '@delab-team/de-ui';
import WebAppSDK from '@twa-dev/sdk';
import { useTranslation } from 'react-i18next';
import TonWeb from 'tonweb';
import { Address, toNano } from '@ton/core';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { Plan } from './plan';
import { Method } from './method';
import { Status } from '../../../../components/status';
import { VPN } from '../../../../logic/vpn';
import { SvgSelector } from '../../../../assets/svg-selector';
import { calculateDaysFromTimestamp } from '../../../../utils/formatDateFromTimestamp';
import { resolveJettonAddressFor, sendJettonToBoc } from '../../../../utils/sendJetton';
import s from './subscribe.module.scss';
import { useHapticFeedback } from '../../../../hooks/useHapticFeedback';
const NEXT_STEP_COLOR = '#40a7e3';
const ERROR_TEXT_COLOR = '#FF0026';
export const Subscribe = ({ isTg, activeRate, setActiveRate, currentStep, setCurrentStep, rawAddress, user, handleIntroductionClose }) => {
    const { t } = useTranslation();
    const TgObj = WebAppSDK;
    const vpn = new VPN();
    const [payToken, setPayToken] = useState(undefined);
    const [assetsData, setAssetsData] = useState(undefined);
    const [isSuccessPay, setIsSuccessPay] = useState(true);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const sendTrans = async (id_user, currency, amount) => {
        const addressVPN = 'UQDILBLIPwj7cFA0SjEzOXdqZ7m-VpfsbAjilWJs_wWkuwjM';
        const TRANSACTION_FEE = 0.18;
        const VALIDITY_DURATION = 60;
        try {
            TgObj.MainButton.showProgress();
            TgObj.MainButton.disable();
            let payload;
            if (currency === 'TON') {
                const cell = new TonWeb.boc.Cell();
                cell.bits.writeUint(0, 32);
                cell.bits.writeString(id_user.toString());
                payload = TonWeb.utils.bytesToBase64(await cell.toBoc());
            }
            else {
                payload = sendJettonToBoc({
                    to: addressVPN,
                    amount: toNano(amount).toString(),
                    comment: id_user.toString()
                }, addressVPN);
            }
            let address;
            if (currency !== 'TON') {
                const jettonWallet = await resolveJettonAddressFor(Address.parse(currency).toString(), Address.parse(rawAddress).toString());
                if (!jettonWallet) {
                    console.error('No jetton wallet address');
                    return false;
                }
                address = Address.parse(jettonWallet).toString();
            }
            const messages = [
                {
                    address: currency === 'TON' ? addressVPN : address,
                    amount: toNano(currency === 'TON' ? amount : TRANSACTION_FEE).toString(),
                    payload
                }
            ].filter(Boolean);
            const tr = {
                validUntil: Math.floor(Date.now() / 1000) + VALIDITY_DURATION,
                messages
            };
            await tonConnectUI.sendTransaction(tr);
            return true;
        }
        catch (error) {
            console.error('Transaction error:', error);
            return false;
        }
        finally {
            TgObj.MainButton.hideProgress();
            TgObj.MainButton.enable();
        }
    };
    // Pay function1
    async function handlePay() {
        if (!payToken || !activeRate)
            return;
        TgObj.MainButton.text = t('common.loading');
        TgObj.MainButton.color = '#78B5F9';
        TgObj.MainButton.disable();
        useHapticFeedback();
        const invoice = await vpn.getInvoice(String(activeRate?.id), payToken.tokenAddress[0], rawAddress);
        TgObj.MainButton.hide();
        const tr = await sendTrans(Number(WebAppSDK.initDataUnsafe.user?.id), payToken?.token === 'TON' ? 'TON' : payToken.tokenAddress[0], invoice.tokenAmount);
        if (tr) {
            setIsPaymentLoading(true);
            TgObj.MainButton.color = '#78B5F9';
            TgObj.MainButton.disable();
            let interval;
            const isPaymentPage = localStorage.getItem('toPaymentPage') === 'true';
            interval = setInterval(async () => {
                const userData = await vpn.checkPayment();
                if (calculateDaysFromTimestamp(Date.parse(userData?.activeTo ?? '0') / 1000) >= 1) {
                    clearInterval(interval);
                    setIsPaymentLoading(false);
                    setIsSuccessPay(true);
                    TgObj.MainButton.color = '#40a7e3';
                    TgObj.MainButton.enable();
                    if (isPaymentPage) {
                        localStorage.removeItem('toPaymentPage');
                        localStorage.setItem('hasPassedIntroduction', 'true');
                        TgObj.showAlert(t('common.congratulations'));
                        handleIntroductionClose();
                    }
                    else {
                        localStorage.setItem('currentIntroductionStep', '4');
                        TgObj.showAlert(t('common.congratulations'));
                        TgObj.MainButton.show();
                        window.location.href = '/introduction';
                    }
                }
            }, 5000);
            setTimeout(async () => {
                const user = await vpn.postAuth();
                clearInterval(interval);
                setIsPaymentLoading(false);
                TgObj.MainButton.color = '#40a7e3';
                localStorage.setItem('currentIntroductionStep', '4');
                TgObj.MainButton.enable();
                setIsSuccessPay(false);
                if (user?.user?.activeTariff?.id === '0' || user?.user?.activeTariff === null) {
                    TgObj.showAlert('Error, please try again');
                    TgObj.MainButton.show();
                }
            }, 120000);
        }
        else {
            TgObj.MainButton.show();
            TgObj.showAlert('Error, please try again');
            setIsPaymentLoading(false);
            TgObj.MainButton.color = '#40a7e3';
            TgObj.MainButton.enable();
        }
    }
    const ton = assetsData?.find(el => el.token === 'TON');
    const enableBtn = () => {
        TgObj.MainButton.show();
        if (currentStep === 2) {
            if (!rawAddress) {
                TgObj.MainButton.text = t('common.connect-btn');
                TgObj.MainButton.color = '#40a7e3';
                TgObj.MainButton.enable();
                return;
            }
            if (activeRate) {
                TgObj.MainButton.text = t('common.next');
                TgObj.MainButton.color = '#40a7e3';
                TgObj.MainButton.enable();
            }
            else {
                TgObj.MainButton.text = t('subscribe.select-plan');
                TgObj.MainButton.color = '#78B5F9';
                TgObj.MainButton.disable();
            }
        }
        else if (currentStep === 3) {
            if (!activeRate?.price)
                return;
            if (activeRate?.price > Number(payToken?.amountUSD)) {
                TgObj.MainButton.text = t('common.insufficient-balance');
                TgObj.MainButton.color = '#78B5F9';
                TgObj.MainButton.disable();
            }
            else if (Number(ton?.amount) < 0.2) {
                TgObj.MainButton.text = t('common.insufficient-balance2');
                TgObj.MainButton.color = '#78B5F9';
                TgObj.MainButton.disable();
            }
            else if (!user || !rawAddress) {
                TgObj.MainButton.text = t('common.loading');
                TgObj.MainButton.color = '#78B5F9';
                TgObj.MainButton.disable();
            }
            else {
                TgObj.MainButton.text = t('common.pay-btn');
                TgObj.MainButton.color = '#40a7e3';
                TgObj.MainButton.enable();
            }
        }
        else if (currentStep === 4) {
            if (isSuccessPay) {
                TgObj.MainButton.text = t('common.return-to-main');
                TgObj.MainButton.color = '#40a7e3';
                TgObj.MainButton.enable();
            }
            else {
                TgObj.MainButton.text = t('common.try-again');
                TgObj.MainButton.color = '#40a7e3';
                TgObj.MainButton.enable();
            }
        }
    };
    const handleBtn = async () => {
        if (currentStep === 2) {
            if (!rawAddress) {
                tonConnectUI.connectWallet();
                useHapticFeedback();
                return;
            }
            const price = activeRate?.price;
            if (price === 0) {
                try {
                    const activationResult = await vpn.activateFree();
                    if (activationResult) {
                        TgObj.showAlert(t('common.congratulations'));
                        localStorage.removeItem('skippedIntroduction');
                        localStorage.setItem('currentIntroductionStep', '4');
                        TgObj.MainButton.show();
                        window.location.href = '/introduction';
                        useHapticFeedback();
                    }
                }
                catch (error) {
                    TgObj.showAlert(t('common.used-subscription'));
                    console.error(error);
                }
            }
            else {
                localStorage.setItem('currentIntroductionStep', '3');
                localStorage.removeItem('skippedIntroduction');
                useHapticFeedback();
                setCurrentStep(3);
            }
        }
        else if (currentStep === 3) {
            await handlePay();
            useHapticFeedback();
        }
        else if (currentStep === 4) {
            if (isSuccessPay) {
                handleIntroductionClose();
                useHapticFeedback();
            }
            else {
                localStorage.setItem('currentIntroductionStep', '3');
                window.location.pathname = '/';
                useHapticFeedback();
            }
        }
    };
    useEffect(() => {
        enableBtn();
        TgObj.MainButton.onClick(handleBtn);
        return () => TgObj.MainButton.offClick(handleBtn);
    }, [currentStep, activeRate, payToken, user, rawAddress]);
    const step = currentStep === 2
        ? t('subscribe.steps.plan')
        : currentStep === 3
            ? t('subscribe.steps.method')
            : currentStep === 4
                ? t('subscribe.steps.confirm')
                : t('subscribe.unknown');
    return (_jsxs(_Fragment, { children: [_jsx(Title, { variant: "h2", className: s.title, children: t('subscribe.title') }), _jsxs("div", { className: s.top, children: [_jsxs("div", { className: s.topInfo, children: [t('common.step'), " ", currentStep - 1, ": ", step] }), _jsx("div", { className: s.topInner, children: _jsxs("div", { className: s.topInner, children: [_jsx("div", { className: `${s.step} ${s.stepActive} ${currentStep >= 3 ? s.stepChecked : ''}`, children: currentStep >= 3 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: currentStep >= 3 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${currentStep >= 3 ? s.stepActive : ''} ${currentStep === 4 ? s.stepChecked : ''}`, children: currentStep >= 4 && _jsx(SvgSelector, { id: "check2" }) }), _jsx("div", { className: s.divider, style: { background: currentStep >= 4 ? NEXT_STEP_COLOR : '' } }), _jsx("div", { className: `${s.step} ${currentStep >= 4 ? s.stepActive : ''} ${!isSuccessPay ? s.stepError : ''}`, style: { color: !isSuccessPay ? ERROR_TEXT_COLOR : '' } })] }) })] }), currentStep === 2 && (_jsx(Plan, { activeRate: activeRate, setActiveRate: setActiveRate, isTg: isTg, handleIntroductionClose: handleIntroductionClose })), currentStep === 3 && (_jsx(Method, { amount: activeRate?.price, activePayToken: payToken, setActivePayToken: setPayToken, currentStep: currentStep, rawAddress: rawAddress, user: user, 
                // payment loading
                isPaymentLoading: isPaymentLoading, isTg: isTg, assetsData: assetsData, setAssetsData: setAssetsData })), currentStep === 4 && _jsx(Status, { isSuccess: isSuccessPay })] }));
};

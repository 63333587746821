import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { Text, Title } from '@delab-team/de-ui';
import * as Oops from '../../assets/stickers/oops.json';
import * as Success from '../../assets/stickers/success.json';
import s from './status.module.scss';
export const Status = ({ isSuccess }) => {
    const approveOptions1 = {
        loop: true,
        autoplay: true,
        animationData: Oops,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const approveOptions2 = {
        loop: true,
        autoplay: true,
        animationData: Success,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    return (_jsx("div", { className: s.inner, children: isSuccess ? (_jsxs(_Fragment, { children: [_jsx(Lottie, { options: approveOptions2, height: 140, isClickToPauseDisabled: true, width: 140 }), _jsx(Title, { variant: "h3", className: s.ready, children: t('status.succ-title') }), _jsx(Text, { className: s.text, children: t('status.succ-description') })] })) : (_jsxs(_Fragment, { children: [_jsx(Lottie, { options: approveOptions1, height: 140, isClickToPauseDisabled: true, width: 140 }), _jsx(Title, { variant: "h3", className: s.error, children: t('status.error-title') }), _jsx(Text, { className: s.text, children: t('status.error-description') })] })) }));
};

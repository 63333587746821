import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { TonApi } from '@delab-team/ton-api-sdk';
import { fromNano } from '@ton/core';
import { Input, Text, Title } from '@delab-team/de-ui';
import Lottie from 'react-lottie';
import { tokensData } from '../../../../../mocks/tokens';
import { SvgSelector } from '../../../../../assets/svg-selector';
import { formatNumber } from '../../../../../utils/formatNumber';
import * as FindStickers from '../../../../../assets/stickers/find.json';
import { useHapticFeedback } from '../../../../../hooks/useHapticFeedback';
import { MethodSkeleton } from './method-skeleton';
import s from './method.module.scss';
export const Method = ({ rawAddress, activePayToken, setActivePayToken, amount, assetsData, setAssetsData, isPaymentLoading, isTg }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: FindStickers,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const [isPaymentPage, setIsPaymentPage] = useState(false);
    const [jettonsLoading, setJettonsLoading] = useState(false);
    const api = new TonApi('AFJOFCU7XVPBKMIAAAAM6H4MQHHW57TAGBG2EY3C3A6USOZUSGXEI6MRDW32YKVQDZBCGUQ', 'mainnet');
    const [searchValue, setSearchValue] = useState('');
    async function getJettons(address) {
        try {
            setJettonsLoading(true);
            const res = await api.Accounts.getAllJettonsBalances(address, 'usd');
            const tokenAddressesArr = tokensData?.map(item => item?.tokenAddress[0]);
            const tonInfo = await api.Accounts.getHumanFriendlyInfo(address);
            const jettonAddresses = res?.balances?.map(el => el?.jetton?.address);
            jettonAddresses?.push('TON');
            const priceRes = await api?.Rates.getTokenPrice(jettonAddresses, 'usd');
            // @ts-ignore
            const tonPrice = priceRes?.rates.TON?.prices?.USD;
            const updatedTokens = tokensData?.map((el) => {
                if (el.token === 'TON') {
                    return {
                        ...el,
                        amount: fromNano(tonInfo.balance),
                        tokenPriceUSD: formatNumber(tonPrice),
                        amountUSD: (tonPrice * Number(fromNano(tonInfo.balance))).toString()
                    };
                }
                const matchingAddresses = tokenAddressesArr?.filter(el3 => priceRes?.rates?.hasOwnProperty(el3));
                for (const matchingAddress of matchingAddresses ?? '') {
                    if (el.tokenAddress[0] === matchingAddress) {
                        // @ts-ignore
                        const matchingBalance = priceRes?.rates[matchingAddress]?.prices?.USD;
                        const tokenPrice = res?.balances.find(el4 => el4?.jetton?.address === matchingAddress);
                        return {
                            ...el,
                            amount: fromNano(tokenPrice.balance),
                            tokenPriceUSD: String(matchingBalance),
                            amountUSD: (parseFloat(fromNano(tokenPrice.balance).toString())
                                * parseFloat(matchingBalance)).toFixed(2)
                        };
                    }
                }
                return el;
            });
            setAssetsData(updatedTokens);
            setActivePayToken(updatedTokens[0]);
            setJettonsLoading(false);
        }
        catch (error) {
            console.error(error);
            setJettonsLoading(false);
        }
        finally {
            setJettonsLoading(false);
        }
    }
    useEffect(() => {
        if (!rawAddress) {
            return;
        }
        getJettons(rawAddress);
    }, [rawAddress]);
    useEffect(() => {
        const isPaymentPage = localStorage.getItem('toPaymentPage');
        if (isPaymentPage) {
            setIsPaymentPage(JSON.parse(isPaymentPage));
        }
    }, []);
    const filteredAssets = assetsData?.filter(el => el.token.toLowerCase().includes(searchValue.toLowerCase()));
    return (_jsx("div", { className: s.method, children: !isPaymentLoading ? (_jsxs(_Fragment, { children: [_jsx(Title, { className: s.methodTitle, variant: "h2", children: t('subscribe.method.title') }), _jsxs("div", { className: s.methodInner, children: [amount && (_jsxs("div", { className: s.methodInnerTotal, children: [_jsx("span", { children: t('subscribe.method.total') }), " ", parseFloat(String(amount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })] })), _jsxs("div", { className: s.methodInputInner, children: [_jsx(SvgSelector, { id: "search" }), _jsx(Input, { variant: "white", value: searchValue, placeholder: t('common.search-token'), onChange: (e) => setSearchValue(e.target.value) }), searchValue.length >= 1 && (_jsx("button", { className: s.methodInputClear, onClick: () => {
                                        setSearchValue('');
                                        useHapticFeedback();
                                    }, children: _jsx(SvgSelector, { id: "clear" }) }))] }), _jsx("ul", { className: s.methodAssets, children: jettonsLoading ? (new Array(5).fill(null).map(_ => _jsx(MethodSkeleton, { isTg: isTg }, v1()))) : (_jsx(_Fragment, { children: filteredAssets && filteredAssets?.length >= 1 ? (filteredAssets?.map(el => (_jsxs("li", { className: s.methodAsset, onClick: () => {
                                        setActivePayToken(el);
                                        useHapticFeedback();
                                    }, children: [_jsxs("div", { className: s.methodAssetLeft, children: [_jsx("div", { className: `${s.methodAssetSelect} ${el.token === activePayToken?.token ? s.methodAssetSelected : ''}`, children: el.token === activePayToken?.token && (_jsx(SvgSelector, { id: "checked" })) }), _jsxs("div", { className: s.methodAssetLeftBody, children: [_jsx("img", { src: el.tokenLogo, width: "40", height: "40", alt: "Token Logo" }), _jsxs("div", { children: [_jsx("div", { className: s.methodAssetToken, children: el.token }), _jsx("span", { className: s.methodAssetAmount, children: formatNumber(el.amount) })] })] })] }), _jsxs("div", { className: s.methodAssetRight, children: [_jsx("span", { children: parseFloat(String(el.amountUSD)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }), _jsx(SvgSelector, { id: "chevron-right" })] })] }, v1())))) : (_jsx(Text, { className: s.methodAssetsEmpty, children: t('common.not-found') })) })) })] })] })) : (_jsxs("div", { className: s.loading, children: [_jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }), _jsxs(Title, { variant: "h1", className: s.transactionTitle, children: [t('pay-await.title'), " ", _jsx("span", { children: "DeVPN" })] }), _jsx(Text, { className: s.transactionDescription, children: t('pay-await.description') })] })) }));
};

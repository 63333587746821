import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useState, useRef, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Text } from '@delab-team/de-ui';
import { motion, AnimatePresence } from 'framer-motion';
import { SvgSelector } from '../../assets/svg-selector';
import { SkeletonInfo } from '../skeleton-info';
import s from './servers-selector.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
export const ServersSelector = ({ serversData, selectedServer, setSelectedServer, isLoading, isTg, userLoading }) => {
    if (!selectedServer)
        return _jsx(_Fragment, {});
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const handleServerSelection = (server) => {
        setSelectedServer(server);
        setIsDropdownOpen(false);
        useHapticFeedback();
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            useHapticFeedback();
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const filteredServersData = serversData.filter(el => el.ip !== selectedServer.ip);
    return (_jsxs("div", { className: s.serversSelector, children: [_jsx("div", { className: `${s.selectedServer} ${s.serverItem}`, onClick: () => !userLoading && setIsDropdownOpen(!isDropdownOpen), ref: buttonRef, children: isLoading ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.serverItemLeft, children: [_jsx("div", { className: s.serverItemFlag }), _jsx(SkeletonInfo, { height: '23', isTg: isTg, widthHalf: true })] }), _jsxs("div", { className: s.serverItemRight, children: [_jsx(SkeletonInfo, { height: '23', isTg: isTg }), _jsx(motion.div, { initial: false, animate: { rotate: isDropdownOpen ? 180 : 0 }, transition: { duration: 0.2 }, children: _jsx(SvgSelector, { id: "chevron-bottom" }) })] })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.serverItemLeft, children: [_jsx(ReactCountryFlag, { countryCode: selectedServer?.geo, svg: true, style: {
                                        width: '2em',
                                        height: '2em'
                                    }, title: "US" }), _jsx(Text, { className: s.serverItemServer, children: selectedServer.name_server })] }), _jsxs("div", { className: s.serverItemRight, children: [_jsx("div", { className: s.serverItemPing, children: _jsxs("div", { className: `${s.serverItemPing} ${Number(selectedServer.load_server) > 90 ? s.serverItemPingRed : ''}`, children: [selectedServer.load_server, " ms"] }) }), _jsx(motion.div, { initial: false, animate: { rotate: isDropdownOpen ? 180 : 0 }, transition: { duration: 0.2 }, children: _jsx(SvgSelector, { id: "chevron-bottom" }) })] })] })) }), _jsx(AnimatePresence, { children: isDropdownOpen && (_jsx(motion.div, { ref: dropdownRef, className: s.serverDropdown, initial: { opacity: 0, height: 0 }, animate: { opacity: 1, height: 'auto' }, exit: { opacity: 0, height: 0 }, transition: { duration: 0.2 }, children: filteredServersData.map(server => (_jsxs("div", { className: s.serverItem, onClick: () => handleServerSelection(server), children: [_jsxs("div", { className: s.serverItemLeft, children: [_jsx(ReactCountryFlag, { countryCode: server?.geo, svg: true, style: {
                                            width: '2em',
                                            height: '2em'
                                        }, title: "US" }), _jsx(Text, { className: s.serverItemServer, children: server.name_server })] }), _jsx("div", { className: s.serverItemRight, children: _jsxs("div", { className: `${s.serverItemPing} ${Number(server.load_server) > 90 ? s.serverItemPingRed : ''}`, children: [server.load_server, " ms"] }) })] }, server.id))) })) })] }));
};

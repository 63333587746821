import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { Text, Title } from '@delab-team/de-ui';
import { Info } from './info';
import { Button } from '../../components/ui/button';
import { DownloadModal } from '../../components/download-modal';
import { Traffic } from '../../components/traffic';
import { ServersSelector } from '../../components/servers-selector';
import { VPN } from '../../logic/vpn';
import { calculateDaysFromTimestamp } from '../../utils/formatDateFromTimestamp';
import { openTelegramLink } from '../../utils/open-telegram-link';
import { SvgSelector } from '../../assets/svg-selector';
import * as speedSticker from '../../assets/stickers/speed.json';
import * as sleepSticker from '../../assets/stickers/sleep.json';
import * as loadingSticker from '../../assets/stickers/loading.json';
import s from './home.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
export const Home = ({ user, keysData, userLoading, isTg, rawAddress }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: speedSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const approveOptions2 = {
        loop: true,
        autoplay: true,
        animationData: sleepSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const approveOptions3 = {
        loop: true,
        autoplay: true,
        animationData: loadingSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const vpn = new VPN();
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [serverData, setServerData] = useState([]);
    const [serverDataLoading, setServerDataLoading] = useState(false);
    const [selectedServer, setSelectedServer] = useState(null);
    const [connectServerData, setConnectServerData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setServerDataLoading(true);
                const res = await vpn.getServers();
                setServerData(res);
                setSelectedServer(res[0] || null);
            }
            catch (error) {
                console.error('Error fetching servers:', error);
            }
            finally {
                setServerDataLoading(false);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const connectServer = keysData?.find(el => el.id === selectedServer?.id);
        if (!connectServer) {
            createKey();
        }
        else {
            setConnectServerData(connectServer);
        }
    }, [selectedServer, keysData]);
    const isPaid = !!user?.user?.activeTariff
        && calculateDaysFromTimestamp(Date.parse(user?.user?.activeTo ?? '0') / 1000) >= 1;
    async function createKey() {
        if (!selectedServer)
            return;
        try {
            const keyData = await vpn.getKey(selectedServer?.id);
            if (keyData.key) {
                setConnectServerData(keyData);
            }
        }
        catch (error) {
            console.error('Error fetching key:', error);
        }
    }
    const handleConnect = (key) => {
        const isWindows = navigator.platform.toUpperCase().includes('WIN');
        if (isWindows) {
            const encodedUrl = encodeURIComponent(key);
            const queryString = new URLSearchParams({ key: encodedUrl }).toString();
            window.open(`/redirect?${queryString}`, '_blank');
        }
        else {
            openTelegramLink(key);
        }
    };
    const handleConnectServer = async () => {
        if (!connectServerData) {
            console.error('connectServerData undefined');
            return;
        }
        // @ts-ignore
        handleConnect(connectServerData.key.key);
    };
    const handleButton = () => {
        useHapticFeedback();
        if (isPaid) {
            handleConnectServer();
        }
        else {
            localStorage.removeItem('hasPassedIntroduction');
            localStorage.setItem('toPaymentPage', 'true');
            localStorage.setItem('currentIntroductionStep', '2');
            window.location.href = '/introduction';
        }
    };
    const limit = isPaid ? user?.infoUser?.limit : 0;
    const used = isPaid ? user?.infoUser?.used : 0;
    return (_jsxs(_Fragment, { children: [showDownloadModal && (_jsx(DownloadModal, { showDownloadModal: showDownloadModal, setShowDownloadModal: setShowDownloadModal })), _jsx(Info, { rawAddress: rawAddress }), _jsx("div", { className: s.status, children: userLoading ? (_jsxs(_Fragment, { children: [_jsx(Lottie, { options: approveOptions3, height: 180, isClickToPauseDisabled: true, width: 180 }), _jsx(Text, { className: s.loading, children: t('common.loading') })] })) : (_jsx(_Fragment, { children: isPaid ? (_jsxs(_Fragment, { children: [_jsx(Lottie, { options: approveOptions, height: 190, isClickToPauseDisabled: true, width: 190 }), _jsx(Title, { variant: "h2", className: s.statusTitle, tgStyles: { color: 'var(--tg-theme-button-color)' }, children: t('home.ready-to-connect') }), _jsx(Text, { className: s.statusText, children: t('common.download-text') })] })) : (_jsxs(_Fragment, { children: [_jsx(Lottie, { options: approveOptions2, height: 180, isClickToPauseDisabled: true, width: 180 }), _jsx(Title, { variant: "h2", className: s.statusTitle, children: t('common.not-active-plan') }), _jsx(Text, { className: s.statusText, children: t('common.choose-plan') })] })) })) }), _jsx(ServersSelector, { serversData: serverData, selectedServer: selectedServer, setSelectedServer: setSelectedServer, isTg: isTg, userLoading: userLoading, isLoading: serverDataLoading }), _jsxs("div", { className: s.connectInner, children: [_jsx(Button, { className: s.connectButton, onClick: handleButton, disabled: userLoading, children: userLoading
                            ? t('common.loading')
                            : isPaid
                                ? t('common.connect')
                                : t('common.select-plan') }), isPaid ? (_jsxs(Button, { className: s.downloadButton, onClick: () => {
                            setShowDownloadModal(true);
                            useHapticFeedback();
                        }, children: [_jsx(SvgSelector, { id: "download" }), t('common.download-app')] })) : (_jsx(_Fragment, {}))] }), _jsxs("div", { className: s.traffic, children: [_jsxs("div", { className: s.trafficTop, children: [_jsx(Title, { variant: "h3", className: s.trafficTitle, children: t('home.traffic-title') }), isPaid ? (_jsxs(Title, { variant: "h3", className: s.trafficTitle, children: [t('home.traffic-update-in'), ' ', calculateDaysFromTimestamp(Date.parse(user?.user?.activeTo ?? '0') / 1000), ' ', calculateDaysFromTimestamp(Date.parse(user?.user?.activeTo ?? '0') / 1000) > 1
                                        ? t('home.days')
                                        : t('home.day')] })) : (_jsx(_Fragment, {}))] }), _jsx(Traffic, { limit: limit, used: used, isTg: isTg, userLoading: userLoading })] })] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Title } from '@delab-team/de-ui';
import WebAppSDK from '@twa-dev/sdk';
import { useTranslation } from 'react-i18next';
import { PromotionPay } from '../../../../../components/promotion-pay';
import { Button } from '../../../../../components/ui/button';
import { VPN } from '../../../../../logic/vpn';
import s from './plan.module.scss';
export const Plan = ({ activeRate, setActiveRate, isTg, handleIntroductionClose }) => {
    // Rates Data
    const [ratesData, setRatesData] = useState([]);
    // Loading Rates Data
    const [ratesLoading, setRatesLoading] = useState(false);
    const [loadingRate, setLoadingRate] = useState(false);
    const isPaymentPage = localStorage.getItem('toPaymentPage') === 'true';
    const { t } = useTranslation();
    const vpn = new VPN();
    useEffect(() => {
        const fetchRates = async () => {
            try {
                setRatesLoading(true);
                const res = await vpn.getRates();
                setRatesData(res);
            }
            finally {
                setRatesLoading(false);
            }
        };
        fetchRates();
    }, []);
    const sortedDataByPrice = ratesData?.slice()?.sort((a, b) => Number(a?.price) - Number(b?.price));
    return (_jsxs("div", { className: s.plan, children: [_jsx(Title, { className: s.planTitle, variant: "h2", children: t('subscribe.actions') }), _jsxs("div", { className: s.planActions, children: [_jsx(PromotionPay, { isTg: isTg, ratesLoading: ratesLoading, activeRate: activeRate, setActiveRate: setActiveRate, data: sortedDataByPrice, showTitle: false, loadingRate: loadingRate }), !isPaymentPage && (_jsx(Button, { className: s.skipButton, onClick: () => {
                            WebAppSDK.MainButton.hide();
                            handleIntroductionClose();
                        }, children: t('common.skip-plan') }))] })] }));
};

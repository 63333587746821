import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/namespace */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTonAddress } from '@tonconnect/ui-react';
import { AppInner } from '@delab-team/de-ui';
import WebAppSDK from '@twa-dev/sdk';
import { Home } from './pages/home';
import { Introduction } from './pages/introduction';
import { Profile } from './pages/profile';
import { SomethingWentWrong } from './pages/something-went-wrong';
import { Redirect } from './pages/redirect';
import { ROUTES } from './utils/router';
import './utils/i18n';
import { VPN } from './logic/vpn';
import './index.scss';
WebAppSDK.ready();
export const App = () => {
    const [firstRender, setFirstRender] = useState(false);
    const [isTg, setIsTg] = useState(false);
    const TgObj = WebAppSDK;
    // user
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    // user end
    const [keysData, setKeysData] = useState([]);
    // Introduction
    const [showIntroduction, setShowIntroduction] = useState(true);
    // Skipped introduction
    const [isSkippedIntroduction, setIsSkippedIntroduction] = useState(false);
    const rawAddress = useTonAddress();
    const navigate = useNavigate();
    const vpn = new VPN();
    // fetch keys data
    const fetchData = useCallback(async () => {
        try {
            setUserLoading(true);
            const userData = await vpn.postAuth();
            if (!userData) {
                throw new Error('User data is not available');
            }
            const [keysData, paymentData] = await Promise.all([vpn.getKeys(), vpn.checkPayment()]);
            setUser(userData);
            setIsError(false);
            setKeysData(keysData);
        }
        catch (error) {
            console.error('Error fetching data:', error);
            setIsError(true);
            navigate(ROUTES.SOMETHING_WENT_WRONG);
        }
        finally {
            setUserLoading(false);
        }
    }, [navigate, vpn]);
    // init twa
    useEffect(() => {
        if (!firstRender && TgObj) {
            setFirstRender(true);
            const isTgCheck = window.Telegram?.WebApp.initData !== '';
            const bodyStyle = document.body.style;
            if (window.location.pathname === ROUTES.SOMETHING_WENT_WRONG && !isError) {
                TgObj.MainButton.hide();
                navigate('/');
            }
            if (!isTgCheck && window.location.pathname === '/redirect') {
                return;
            }
            if (isTgCheck) {
                TgObj.ready();
                TgObj.enableClosingConfirmation();
                TgObj.expand();
                setIsTg(true);
                fetchData();
                bodyStyle.backgroundColor = 'var(--tg-theme-secondary-bg-color)';
                bodyStyle.setProperty('background-color', 'var(--tg-theme-secondary-bg-color)', 'important');
            }
            else {
                navigate(ROUTES.SOMETHING_WENT_WRONG);
            }
            if (window.location.pathname !== ROUTES.INTRODUCTION) {
                if (!isTg) {
                    return;
                }
                TgObj.requestWriteAccess();
            }
        }
    }, [firstRender, isError, fetchData, navigate, TgObj]);
    // introduction check
    useEffect(() => {
        const isTgCheck = window.Telegram?.WebApp.initData !== '';
        const hasPassedIntroduction = localStorage.getItem('hasPassedIntroduction');
        if (window.location.pathname === ROUTES.REDIRECT) {
            return;
        }
        if (!isTgCheck) {
            navigate(ROUTES.SOMETHING_WENT_WRONG);
            return;
        }
        if (hasPassedIntroduction) {
            setShowIntroduction(false);
        }
        else {
            navigate(ROUTES.INTRODUCTION);
        }
    }, [navigate]);
    // introduction skip check
    useEffect(() => {
        const hasSkippedIntroduction = localStorage.getItem('skippedIntroduction');
        if (hasSkippedIntroduction) {
            setIsSkippedIntroduction(true);
        }
    }, []);
    useEffect(() => {
        if (window.location.pathname === ROUTES.HOME) {
            TgObj.BackButton.hide();
        }
    }, [window.location.pathname, TgObj]);
    // ===================================================
    const savedLanguage = localStorage.getItem('i18nextLng');
    const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage || 'en');
    const { i18n } = useTranslation();
    useEffect(() => {
        const initializeLanguage = async () => {
            const TgLanguage = TgObj?.initDataUnsafe?.user?.language_code;
            let language;
            const userDefinedLanguage = localStorage.getItem('i18nextLngOwn');
            if (userDefinedLanguage) {
                language = userDefinedLanguage;
            }
            else if (TgLanguage) {
                const lowerCaseTgLanguage = TgLanguage.toLowerCase();
                if (['ru', 'en'].includes(lowerCaseTgLanguage)) {
                    language = lowerCaseTgLanguage;
                }
                else {
                    language = 'en';
                }
            }
            else {
                language = 'en';
            }
            setSelectedLanguage(language);
        };
        if (isTg && !savedLanguage) {
            initializeLanguage();
        }
    }, [isTg, savedLanguage, TgObj]);
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    }, [selectedLanguage, i18n]);
    //= =======================================================
    return (_jsx(AppInner, { isTg: isTg, children: _jsx("div", { className: "wrapper", children: !showIntroduction ? (_jsxs(Routes, { children: [_jsx(Route, { path: ROUTES.HOME, element: _jsx(Home, { rawAddress: rawAddress, isSkippedIntroduction: isSkippedIntroduction, isTg: isTg, keysData: keysData, user: user, userLoading: userLoading }) }), _jsx(Route, { path: ROUTES.INTRODUCTION, element: _jsx(Introduction, { rawAddress: rawAddress, user: user, keysData: keysData, isTg: isTg, setShowIntroduction: setShowIntroduction }) }), _jsx(Route, { path: ROUTES.PROFILE, element: _jsx(Profile, { rawAddress: rawAddress, selectedLanguage: selectedLanguage, setSelectedLanguage: setSelectedLanguage }) }), _jsx(Route, { path: ROUTES.SOMETHING_WENT_WRONG, element: _jsx(SomethingWentWrong, {}) }), _jsx(Route, { path: ROUTES.REDIRECT, element: _jsx(Redirect, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: ROUTES.HOME, replace: true }) })] })) : (_jsxs(Routes, { children: [_jsx(Route, { path: ROUTES.INTRODUCTION, element: _jsx(Introduction, { rawAddress: rawAddress, user: user, keysData: keysData, isTg: isTg, setShowIntroduction: setShowIntroduction }) }), _jsx(Route, { path: ROUTES.SOMETHING_WENT_WRONG, element: _jsx(SomethingWentWrong, {}) }), _jsx(Route, { path: ROUTES.REDIRECT, element: _jsx(Redirect, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: ROUTES.HOME, replace: true }) })] })) }) }));
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Div, Li, Title, Text } from '@delab-team/de-ui';
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { SkeletonInfo } from '../skeleton-info';
import { SvgSelector } from '../../assets/svg-selector';
import s from './promotion-pay.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const buttonTgStyles = {
    background: 'var(--tg-theme-button-color)',
    color: 'var(--tg-theme-button-text-color)'
};
export const PromotionPay = ({ isTg, data, activeRate, setActiveRate, ratesLoading, setShow, showPayModal = false, showTitle = true, loadingRate }) => {
    const { t } = useTranslation();
    const [isSelected, setIsSelected] = useState(false);
    return (_jsxs("div", { className: s.promotion, children: [showTitle && (_jsx(Title, { variant: "h3", className: s.promotionTitle, tgStyles: textTgStyles, children: t('promotions-page.promotion-pay.title') })), _jsx("ul", { className: s.promotionList, children: ratesLoading
                    ? new Array(5).fill(null).map(_ => (_jsx(Div, { className: `${s.promotionLi}`, tgStyles: {
                            border: '1px solid var(--tg-theme-bg-color)',
                            background: 'transparent'
                        }, children: _jsxs("div", { className: s.promotionLiAction, children: [_jsx(Div, { className: `${s.promotionLiButton}`, tgStyles: { border: '1px solid var(--tg-theme-button-color)' } }), _jsxs("div", { className: s.promotionLiBody, children: [_jsxs("div", { className: s.promotionLiLeft, children: [_jsx("div", { className: s.promotionLiInfo, children: _jsx(Text, { className: s.promotionLiTitle, tgStyles: textTgStyles, children: _jsx(SkeletonInfo, { widthFull: true, isTg: isTg }) }) }), _jsxs(Text, { className: s.promotionLiDescription, tgStyles: textTgStyles, children: [_jsx(SkeletonInfo, { isTg: isTg }), ' ', _jsx(SkeletonInfo, { isTg: isTg })] })] }), _jsx("div", { className: s.promotionLiRight, children: _jsx(Text, { className: s.promotionLiTitle, tgStyles: textTgStyles, children: _jsx(SkeletonInfo, { widthHalf: true, isTg: isTg }) }) })] })] }) }, v1())))
                    : data.map(el => (_jsx(Li, { className: `${s.promotionLi} ${activeRate?.id === el?.id ? s.promotionLiActive : ''}`, onClick: () => {
                            setActiveRate(el);
                            localStorage.setItem('activeRate', JSON.stringify(el));
                            setIsSelected(!isSelected);
                            useHapticFeedback();
                        }, children: _jsxs("div", { className: s.promotionLiAction, children: [_jsx(Div, { className: `${s.promotionLiButton} ${activeRate?.id === el?.id ? s.promotionLiButtonActive : ''}`, onClick: () => {
                                        setActiveRate(el);
                                        localStorage.setItem('activeRate', JSON.stringify(el));
                                        setIsSelected(!isSelected);
                                        useHapticFeedback();
                                    }, children: activeRate?.id === el?.id ? (_jsx(SvgSelector, { id: "checked" })) : null }), _jsxs("div", { className: s.promotionLiBody, children: [_jsxs("div", { className: s.promotionLiLeft, children: [_jsxs("div", { className: s.promotionLiInfo, children: [_jsx(Text, { className: s.promotionLiTitle, children: el?.name_rate }), el.price_old !== 0 && el.price !== 0 && (_jsxs("div", { className: s.promotionLiBadge, children: ["-", Math.round(((el.price_old - el.price)
                                                                    / el.price_old)
                                                                    * 100), "%"] }))] }), _jsxs(Text, { className: s.promotionLiDescription, tgStyles: textTgStyles, children: [el?.price_old !== 0 && (_jsxs("span", { className: s.promotionLiOldPrice, children: ["$", el?.price_old] })), ' ', "$", el?.price, " / ", el?.name_rate] })] }), _jsx("div", { className: s.promotionLiRight, children: _jsx(Text, { className: s.promotionLiPrice, children: el.count_days === 7 ? ('Free / 7 days') : (_jsxs(_Fragment, { children: ["$", el?.price_m.toFixed(2), " / month"] })) }) })] })] }) }, v1()))) }), showPayModal && setShow && (_jsx(Button, { className: s.promotionActionPay, onClick: () => {
                    localStorage.removeItem('hasPassedIntroduction');
                    localStorage.setItem('toPaymentPage', 'true');
                    localStorage.setItem('currentIntroductionStep', '3');
                    window.location.href = '/introduction';
                }, tgStyles: buttonTgStyles, disabled: !activeRate || loadingRate, children: t('common.pay-btn') }))] }));
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { Alert, Div, Text, Title } from '@delab-team/de-ui';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { motion } from 'framer-motion';
import { SvgSelector } from '../../assets/svg-selector';
import s from './profile.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
export const Profile = ({ rawAddress, selectedLanguage, setSelectedLanguage }) => {
    const { t } = useTranslation();
    const TgObj = WebApp;
    const navigate = useNavigate();
    const [isCopiedAddress, setIsCopiedAddress] = useState(false);
    const [isCopiedUsername, setIsCopiedUsername] = useState(false);
    const handlePrev = () => {
        TgObj.BackButton.hide();
        navigate('/');
        useHapticFeedback();
    };
    useEffect(() => {
        TgObj.BackButton.show();
        TgObj.BackButton.onClick(handlePrev);
        return () => {
            TgObj.BackButton.offClick(handlePrev);
        };
    }, []);
    const handleNavigate = () => {
        window.localStorage.clear();
        window.location.pathname = '/';
        useHapticFeedback();
    };
    const handleExit = () => {
        TgObj.showConfirm(t('common.you-sure'), (isConfirmed) => {
            if (isConfirmed) {
                handleNavigate();
            }
        });
    };
    return (_jsxs("div", { children: [isCopiedAddress && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedAddress(false), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", tgStyles: { background: 'var(--tg-theme-button-color)' }, className: s.alert, children: _jsx("span", { className: s.alertText, children: t('common.address-copied') }) })), isCopiedUsername && (_jsx(Alert, { type: "important", onClose: () => setIsCopiedUsername(false), icon: _jsx(_Fragment, { children: "\uD83D\uDCC3" }), autoCloseTimeout: 1000, position: "top-right", tgStyles: { background: 'var(--tg-theme-button-color)' }, className: s.alert, children: _jsx("span", { className: s.alertText, children: t('common.username-copied') }) })), _jsxs("div", { className: s.userTg, children: [_jsxs("div", { className: s.userTgInner, children: [_jsx("img", { src: `https://t.me/i/userpic/320/${TgObj?.initDataUnsafe?.user?.username}.jpg`, onLoad: (e) => {
                                    const target = e.currentTarget;
                                    if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                                        target.style.display = 'none';
                                    }
                                }, alt: "" }), _jsx("div", { className: s.avatar, children: TgObj?.initDataUnsafe?.user?.username?.slice(0, 2) })] }), _jsxs("div", { children: [_jsxs("div", { className: s.name, children: [_jsx(Div, { tgStyles: { color: 'var(--tg-theme-text-color)' }, children: TgObj?.initDataUnsafe?.user?.first_name }), _jsx(Div, { tgStyles: { color: 'var(--tg-theme-text-color)' }, children: TgObj?.initDataUnsafe?.user?.last_name })] }), TgObj?.initDataUnsafe?.user?.username && TgObj?.initDataUnsafe?.user?.username?.length >= 1 && (_jsxs("div", { className: s.username, onClick: () => {
                                    setIsCopiedUsername(true);
                                    useHapticFeedback();
                                }, children: ["@", TgObj?.initDataUnsafe?.user?.username] }))] })] }), rawAddress && (_jsxs(_Fragment, { children: [_jsx(Title, { variant: "h3", className: s.title, children: t('common.address') }), _jsx("div", { className: s.action, children: _jsx("div", { className: `${s.accountAction} ${s.accountAddress}`, onClick: () => {
                                copy(rawAddress);
                                setIsCopiedAddress(true);
                                useHapticFeedback();
                            }, children: _jsxs("div", { className: s.address, children: [rawAddress, " ", _jsx(SvgSelector, { id: "copy" })] }) }) })] })), _jsx(Title, { variant: "h3", className: s.title, children: t('common.language') }), _jsxs("div", { className: `${s.action}`, children: [_jsxs(motion.button, { className: s.language, onClick: () => {
                            setSelectedLanguage('ru');
                            localStorage.setItem('i18nextLngOwn', 'ru');
                            useHapticFeedback();
                        }, whileHover: "hover", initial: "nonHover", children: [_jsxs("div", { className: s.languageBody, children: [_jsx(ReactCountryFlag, { countryCode: "ru", style: {
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }, svg: true }), _jsx(Text, { className: s.languageText, children: "\u0420\u0443\u0441\u0441\u043A\u0438\u0439" })] }), selectedLanguage === 'ru' && (_jsx(SvgSelector, { id: "check3" }))] }), _jsxs(motion.button, { className: s.language, onClick: () => {
                            setSelectedLanguage('en');
                            localStorage.setItem('i18nextLngOwn', 'en');
                            useHapticFeedback();
                        }, whileHover: "hover", initial: "nonHover", children: [_jsxs("div", { className: s.languageBody, children: [_jsx(ReactCountryFlag, { countryCode: "us", style: {
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }, svg: true }), _jsx(Text, { className: s.languageText, children: "English" })] }), selectedLanguage === 'en' && (_jsx(SvgSelector, { id: "check3" }))] })] }), _jsx(Title, { variant: "h3", className: s.title, children: t('common.actions') }), _jsxs("div", { className: `${s.action}`, children: [_jsxs(motion.button, { className: `${s.actionButton}`, whileHover: "hover", initial: "nonHover", onClick: () => {
                            window.open('https://t.me/delabsupportbot', '_blank');
                            useHapticFeedback();
                        }, children: [_jsx(SvgSelector, { id: "link" }), _jsx("div", { className: s.actionButtonInner, children: _jsx("div", { className: `${s.accountAction} ${s.actionText}`, children: t('common.support') }) })] }), _jsxs(motion.button, { className: `${s.actionButton}`, onClick: handleExit, whileHover: "hover", initial: "nonHover", children: [_jsx(SvgSelector, { id: "exit" }), _jsx("div", { className: s.actionButtonInner, children: _jsx("div", { className: `${s.accountAction} ${s.logout}`, children: t('common.logout') }) })] })] })] }));
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CPGWioxLMSYna0uqJOJ_{color:var(--tg-theme-hint-color);font-size:13px;margin:35px 0 8px 0;font-weight:410;text-transform:uppercase;padding-left:16px}.moUiRwFjQxdevSqFxL2m{background:var(--tg-theme-bg-color);padding:12px 16px;border-radius:12px;margin-bottom:5px}.gYom12Yzu8CYNG3K1GR_ .AIXfjd4Imk6eMUW0LFNu{margin-top:-5px;padding:7px 0;background-color:rgba(0,0,0,0) !important;color:var(--tg-theme-hint-color) !important;transition:all .3s ease 0s}.gYom12Yzu8CYNG3K1GR_ .AIXfjd4Imk6eMUW0LFNu:hover{opacity:.8}`, "",{"version":3,"sources":["webpack://./src/pages/introduction/introduction-steps/subscribe/plan/plan.module.scss"],"names":[],"mappings":"AACE,sBACE,gCAAA,CACA,cAAA,CACA,mBAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CAEF,sBACE,mCAAA,CACA,iBAAA,CACA,kBAAA,CACA,iBAAA,CAEF,4CACE,eAAA,CACA,aAAA,CACA,yCAAA,CACA,2CAAA,CACA,0BAAA,CACA,kDACE,UAAA","sourcesContent":[".plan {\n  &Title {\n    color: var(--tg-theme-hint-color);\n    font-size: 13px;\n    margin: 35px 0 8px 0;\n    font-weight: 410;\n    text-transform: uppercase;\n    padding-left: 16px;\n  }\n  &Actions {\n    background: var(--tg-theme-bg-color);\n    padding: 12px 16px;\n    border-radius: 12px;\n    margin-bottom: 5px;\n  }\n  .skipButton {\n    margin-top: -5px;\n    padding: 7px 0;\n    background-color: transparent !important;\n    color: var(--tg-theme-hint-color) !important;\n    transition: all 0.3s ease 0s;\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planTitle": `CPGWioxLMSYna0uqJOJ_`,
	"planActions": `moUiRwFjQxdevSqFxL2m`,
	"plan": `gYom12Yzu8CYNG3K1GR_`,
	"skipButton": `AIXfjd4Imk6eMUW0LFNu`
};
export default ___CSS_LOADER_EXPORT___;

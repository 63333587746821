// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NVEqiGJuO4yc_8SNKnXU{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100vh;position:relative}.ZSjL7E58c5QNi2Ei0k4g{margin-top:50px}.qLTvQKIEVOQRDoru36xp{font-size:28px;font-weight:760;margin:20px 0;text-align:center;color:var(--tg-theme-text-color)}.qLTvQKIEVOQRDoru36xp span{color:var(--tg-theme-button-color)}.FabT7_5RPP6mJk2aoEni{text-align:center;font-size:16px;color:var(--tg-theme-hint-color)}.FabT7_5RPP6mJk2aoEni:not(:last-child){margin-top:18px}.HAMXtopiUq1vnOanH1MP{margin:20px 0 5px 0}.bMdpFECznXDl5WcpXpCq{background-color:rgba(0,0,0,0) !important;color:var(--tg-theme-hint-color) !important;transition:all .3s ease 0s}.bMdpFECznXDl5WcpXpCq:hover{opacity:.8}`, "",{"version":3,"sources":["webpack://./src/pages/introduction/introduction-steps/first-step/first-step.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,gCAAA,CACA,2BACE,kCAAA,CAIJ,sBACE,iBAAA,CACA,cAAA,CACA,gCAAA,CACA,uCACE,eAAA,CAIJ,sBACE,mBAAA,CAGF,sBACE,yCAAA,CACA,2CAAA,CACA,0BAAA,CACA,4BACE,UAAA","sourcesContent":[".firstStep {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  position: relative;\n}\n\n.content {\n  margin-top: 50px;\n}\n\n.firstTitle {\n  font-size: 28px;\n  font-weight: 760;\n  margin: 20px 0;\n  text-align: center;\n  color: var(--tg-theme-text-color);\n  span {\n    color: var(--tg-theme-button-color);\n  }\n}\n\n.firstText {\n  text-align: center;\n  font-size: 16px;\n  color: var(--tg-theme-hint-color);\n  &:not(:last-child) {\n    margin-top: 18px;\n  }\n}\n\n.firstButton {\n  margin: 20px 0 5px 0;\n}\n\n.skipButton {\n  background-color: transparent !important;\n  color: var(--tg-theme-hint-color) !important;\n  transition: all 0.3s ease 0s;\n  &:hover {\n    opacity: 0.8;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstStep": `NVEqiGJuO4yc_8SNKnXU`,
	"content": `ZSjL7E58c5QNi2Ei0k4g`,
	"firstTitle": `qLTvQKIEVOQRDoru36xp`,
	"firstText": `FabT7_5RPP6mJk2aoEni`,
	"firstButton": `HAMXtopiUq1vnOanH1MP`,
	"skipButton": `bMdpFECznXDl5WcpXpCq`
};
export default ___CSS_LOADER_EXPORT___;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDataSize } from '../../utils/format-data-size';
import s from './traffic.module.scss';
import { SvgSelector } from '../../assets/svg-selector';
import { SkeletonInfo } from '../skeleton-info';
export const Traffic = ({ limit = 0, used = 0, userLoading, isTg }) => {
    const percentageUsed = (limit / used) * 100;
    let backgroundStyle;
    if (percentageUsed <= 10) {
        backgroundStyle = '#F95454';
    }
    else if (percentageUsed >= 10 && percentageUsed <= 70) {
        backgroundStyle = '#F67B22';
    }
    else {
        backgroundStyle = '#007AFF';
    }
    return (_jsxs("div", { className: s.trafficContainer, children: [_jsx("div", { className: s.usageInfo, children: _jsxs("div", { className: s.usageData, children: [_jsx(SvgSelector, { id: "earth" }), _jsxs("div", { className: s.usageDataInfo, children: [userLoading ? _jsx(SkeletonInfo, { widthHalf: true, isTg: isTg }) : (_jsx("span", { children: formatDataSize(used).replace('.', '.') })), _jsx("span", { children: " / " }), userLoading ? _jsx(SkeletonInfo, { widthHalf: true, isTg: isTg }) : (_jsx("span", { children: formatDataSize(limit).replace('.', '.') }))] })] }) }), _jsx("div", { className: s.progressBar, children: _jsx("div", { className: s.progressBarLine, style: { width: `${100 - percentageUsed}%`, background: userLoading ? 'transparent' : backgroundStyle } }) })] }));
};

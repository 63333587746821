import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Li, Modal, Text, Title } from '@delab-team/de-ui';
import { DownloadData } from '../../mocks/download-data';
import { SvgSelector } from '../../assets/svg-selector';
import s from './download-modal.module.scss';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
const modalTgStyles = { backgroundColor: 'var(--tg-theme-bg-color)' };
export const DownloadModal = ({ showDownloadModal, setShowDownloadModal }) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (showDownloadModal) {
            window.document.body.style.overflow = 'hidden';
        }
        return () => {
            window.document.body.style.overflow = 'visible';
        };
    }, [showDownloadModal]);
    const handleCloseModal = () => {
        setShowDownloadModal(false);
        useHapticFeedback();
        window.document.body.style.overflow = 'auto';
    };
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let filteredData;
    if (/android/i.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'Android');
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        filteredData = DownloadData.find(el => el.name === 'IOS');
    }
    else if (/Mac/.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'macOS');
    }
    else if (/Win/.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'Windows');
    }
    else if (/Linux/.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'Linux');
    }
    else {
        console.log('The operating system could not be determined.');
    }
    return (_jsx("div", { className: s.wrapper, children: _jsxs(Modal, { isOpen: showDownloadModal, onClose: handleCloseModal, isCloseButton: false, className: s.modal, tgStyles: { modalContent: modalTgStyles }, children: [_jsx("div", { className: s.modalTop, children: _jsx(Button, { className: s.modalTopButton, tgStyles: { color: 'var(--tg-theme-link-color)' }, onClick: () => {
                            handleCloseModal();
                        }, children: t('common.close') }) }), _jsx(Title, { variant: "h1", className: s.modalTitle, children: t('download.title') }), _jsx(Text, { className: s.modalDescription, children: t('download.description') }), _jsx("ul", { className: s.downloadList, children: DownloadData.map(item => (_jsx(Li, { className: `${s.downloadItem} ${item === filteredData ? s.selected : ''}`, children: _jsxs(Link, { to: item.link || '', target: "_blank", children: [_jsxs("div", { className: s.downloadItemInfo, children: [item.icon, _jsx(Text, { className: s.downloadItemName, children: item.name }), item === filteredData && _jsx(SvgSelector, { id: "check3" })] }), _jsx(SvgSelector, { id: "download2" })] }) }, item.name))) })] }) }));
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e6ukFzQEmthfjNvJKsIk{display:flex;align-items:center;justify-content:center;flex-direction:column;height:100vh}.wNwdAou4IMRoXkq7OtvM{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.WyqwLCl09WOEr9mA7ZNZ{font-size:28px;font-weight:760;line-height:120%;max-width:250px;margin:10px 0;text-align:center;color:var(--tg-theme-text-color)}.mUF9Ay1P67tz6J7WgSRh{color:#ff3e3e}.oBjNJLld__gb404ZA4Z6{font-size:16px;margin-bottom:12px;text-align:center;color:var(--tg-theme-hint-color)}`, "",{"version":3,"sources":["webpack://./src/pages/something-went-wrong/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,YAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,gCAAA,CACA,sBACE,aAAA,CAGJ,sBACE,cAAA,CACA,kBAAA,CACA,iBAAA,CACA,gCAAA","sourcesContent":[".inner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Title {\n    font-size: 28px;\n    font-weight: 760;\n    line-height: 120%;\n    max-width: 250px;\n    margin: 10px 0;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n    &Red {\n      color: #FF3E3E;\n    }\n  }\n  &Text {\n    font-size: 16px;\n    margin-bottom: 12px;\n    text-align: center;\n    color: var(--tg-theme-hint-color); \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `e6ukFzQEmthfjNvJKsIk`,
	"innerImg": `wNwdAou4IMRoXkq7OtvM`,
	"innerTitle": `WyqwLCl09WOEr9mA7ZNZ`,
	"innerTitleRed": `mUF9Ay1P67tz6J7WgSRh`,
	"innerText": `oBjNJLld__gb404ZA4Z6`
};
export default ___CSS_LOADER_EXPORT___;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { Title } from '@delab-team/de-ui';
import * as oopsSticker from '../../assets/stickers/oops.json';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import s from './styles.module.scss';
export const SomethingWentWrong = () => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: oopsSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const TgObj = WebAppSDK;
    const handleButton = () => {
        window.open('https://t.me/delabvpnbot');
        useHapticFeedback();
    };
    useEffect(() => {
        TgObj.MainButton.show();
        TgObj.MainButton.text = t('common.to-app');
        TgObj.MainButton.onClick(handleButton);
        return () => TgObj.MainButton.offClick(handleButton);
    }, []);
    const isTgCheck = window.Telegram.WebApp.initData !== '';
    return (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.innerImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx(Title, { variant: "h2", className: `${s.innerTitle} ${s.innerTitleRed}`, children: t('common.oops') }), _jsx(Title, { variant: "h2", className: s.innerText, children: !isTgCheck ? t('common.error-tg') : t('common.something-went-wrong') })] }));
};

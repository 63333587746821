/* eslint-disable class-methods-use-this */
import axios from 'axios';
var ApiEndpoints;
(function (ApiEndpoints) {
    ApiEndpoints["GET_USER"] = "api/v2/user/getUser";
    ApiEndpoints["CHECK_PAYMENT"] = "api/v2/tariff/checkPayment";
    ApiEndpoints["GET_SERVERS"] = "getServers";
    ApiEndpoints["GET_TARIFFS"] = "api/v2/tariff/getTariffs";
    ApiEndpoints["GET_KEYS"] = "getKeys";
    ApiEndpoints["ACTIVATE_FREE"] = "activateFree";
    ApiEndpoints["GET_KEY"] = "getKey";
    ApiEndpoints["CREATE_TRANSACTION"] = "api/v2/tariff/createTransaction";
})(ApiEndpoints || (ApiEndpoints = {}));
export class VPN {
    constructor() {
        this._url = 'https://lobster-app-7recs.ondigitalocean.app/';
    }
    getHeaders() {
        return { 'telegram-data': window.Telegram.WebApp.initData };
    }
    async get(url, data) {
        const res = await axios.get(`${this._url}${url}?${new URLSearchParams(data)}`, { headers: this.getHeaders() });
        return res.data;
    }
    async post(url, data) {
        const res = await axios.post(`${this._url}${url}`, data, { headers: this.getHeaders() });
        return res;
    }
    async postAuth() {
        const res = await axios.get(`${this._url}${ApiEndpoints.GET_USER}`, { headers: this.getHeaders() });
        return res.data;
    }
    async checkPayment() {
        const res = await axios.get(`${this._url}${ApiEndpoints.CHECK_PAYMENT}`, { headers: this.getHeaders() });
        return res.data;
    }
    async getServers() {
        const res = await this.get(ApiEndpoints.GET_SERVERS, {});
        return res.data;
    }
    async getRates() {
        const res = await axios.get(`${this._url}${ApiEndpoints.GET_TARIFFS}`, { headers: this.getHeaders() });
        return res.data;
    }
    async getKeys() {
        const res = await this.post(ApiEndpoints.GET_KEYS, {});
        return res.data.keys;
    }
    // TODO
    async activateFree() {
        const data = await this.post(ApiEndpoints.ACTIVATE_FREE, { tg_data: window.Telegram.WebApp.initData });
        return data;
    }
    async getKey(id_server) {
        // const data = querystring.stringify({ id_server })
        const res = await axios.post(`${this._url}${ApiEndpoints.GET_KEY}`, { id_server: id_server }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...this.getHeaders()
            }
        });
        return res.data;
    }
    // TODO
    async getInvoice(tariffId, tokenAddress, userAddress) {
        const res = await axios.get(`${this._url}${ApiEndpoints.CREATE_TRANSACTION}?tariffId=${tariffId}&tokenAddress=${tokenAddress}&userAddress=${userAddress}`, { headers: this.getHeaders() });
        return res.data;
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { Text, Title } from '@delab-team/de-ui';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import WebAppSDK from '@twa-dev/sdk';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { Button } from '../../../../components/ui/button';
import { useHapticFeedback } from '../../../../hooks/useHapticFeedback';
import { calculateDaysFromTimestamp } from '../../../../utils/formatDateFromTimestamp';
import * as helloSticker from '../../../../assets/stickers/hello.json';
import s from './first-step.module.scss';
export const FirstStep = ({ handleNextStep, currentStep, rawAddress, handleIntroductionClose, user }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: helloSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const TgObj = WebAppSDK;
    const { t } = useTranslation();
    const auth = !!localStorage.getItem('ton-connect-ui_wallet-info');
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const isPaidUser = () => {
        const activeTariff = user?.user?.activeTariff;
        if (activeTariff === null || activeTariff?.id === null) {
            return false;
        }
        if (calculateDaysFromTimestamp(Date.parse(user?.user?.activeTo ?? '0') / 1000) >= 1) {
            return true;
        }
        return false;
    };
    const isPaid = isPaidUser();
    useEffect(() => {
        if (currentStep !== 1 || !auth)
            return;
        if (isPaid) {
            handleIntroductionClose();
        }
        else {
            handleNextStep();
        }
    }, [rawAddress]);
    return (_jsx("div", { className: s.firstStep, children: _jsxs("div", { className: s.content, children: [_jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }), _jsxs(Title, { variant: "h1", className: s.firstTitle, children: [t('introduction.welcome-title'), " ", _jsx("span", { children: "DeVPN" })] }), _jsx(Text, { className: s.firstText, children: t('introduction.welcome-description1') }), _jsx(Text, { className: s.firstText, children: t('introduction.welcome-description2') }), _jsx(Button, { className: s.firstButton, onClick: () => {
                        tonConnectUI.connectWallet();
                        useHapticFeedback();
                    }, children: t('common.connect-btn') }), _jsx(Button, { className: s.skipButton, onClick: () => handleIntroductionClose(), children: t('common.skip') })] }) }));
};
